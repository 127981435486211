import { Box } from "@mui/material";
import React from "react";

export const Logo = () => (
  <Box sx={{ display: "flex", alignItems: "flex-end", mr: "30px" }}>
    <Box
      sx={{ width: 150, height: 48 }}
      component="img"
      src="/logos/full-logo.svg"
    />
  </Box>
);
