export const menuItems = [
    {
        title: "Home",
        anchor: 'home',
    },
    {
        title: "Services",
        anchor: 'services',
    },
    {
        title: "About Us",
        anchor: 'about-us',
    },
    {
        title: "Contact",
        anchor: 'contact',
    },
];
