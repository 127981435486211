import React, { useState, useCallback, ReactNode, FC } from "react";
import PropTypes from "prop-types";
export const NavigationContext = React.createContext({
  active: 0,
  setActiveLink: (index: number) => {},
});

interface NavigationContextProviderProps {
  children?: ReactNode;
}

export const NavigationContextProvider: FC<NavigationContextProviderProps> = ({
  children,
}) => {
  const [active, setActive] = useState(0);

  const setActiveLink = useCallback(
    (currentActive: number) => {
      setActive(currentActive);
    },
    [setActive]
  );

  const value = { setActiveLink, active };
  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};

NavigationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
