import { Box, Drawer } from "@mui/material";
import React from "react";
import { FC } from "react";
import Scrollbar from "../../Scrollbar";
import { localConfig } from "../../../services/config";
import { menuItems } from "../items";
import SidebarItem from "../SidebarItem";

interface SidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
  sx: {};
}

const Sidebar: FC<SidebarProps> = (props) => {
  const { onMobileClose, openMobile, sx } = props;
  // const location = useLocation();

  // useEffect(() => {
  //   if (openMobile && onMobileClose) {
  //     onMobileClose();
  //   }
  // }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          p: 2,
        }}
      >
        {/* <RouterLink to="/">

        </RouterLink> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      ></Box>
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ p: 2 }}>
          {menuItems.map((item, index) => (
            <React.Fragment key={item.title}>
              <SidebarItem
                index={index}
                anchor={item.anchor}
                title={item.title}
                onMobileClose={onMobileClose}
              />
            </React.Fragment>
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "#013d50",
          width: localConfig.SIDEBAR_WIDTH,
          ...sx,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default Sidebar;
