import { Box, Container, Typography } from "@mui/material";
// import OnVisible, { setDefaultProps } from "react-on-visible";

export const Contact: React.FC = () => {
  return (
    <Container>
      <Typography variant="h2" sx={{ mb: "40px" }}>
        Contact
      </Typography>
      <Box component="div">
        <Box component="p">
          For more information, contact us at info@codewyrm.com
        </Box>
      </Box>
    </Container>
  );
};
