import { FiberManualRecord } from "@mui/icons-material";
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

export const Services: React.FC = () => {
  const items = [
    "Requirements gathering and analysis",
    "Software Consulting & Development",
    "Web & Mobile Development",
    "Project management",
    "Cloud infrastructure setup, maintenance and migrations.",
    "Data storage and analytics",
  ];

  return (
    <Container>
      <Typography variant="h2" sx={{ mb: "40px" }}>
        SERVICES
      </Typography>
      <Box component="div">
        Our services help any company build up their technology stack with
        experienced members for any role including:
        <List>
          {items.map((item) => (
            <ListItem disablePadding key={item}>
              <ListItemIcon>
                <FiberManualRecord fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Container>
  );
};
