import { Box } from "@mui/material";
import { useContext, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Footer } from "../../sections/Footer";
import { NavigationContext } from "../../contexts/navigation-context";
import { AboutUs } from "../../sections/AboutUs";
import { Contact } from "../../sections/Contact";
import { Home } from "../../sections/Home";
import { Services } from "../../sections/Services";

export const Test: React.FC = () => {
  const [ref1, inView1] = useInView({
    threshold: 1,
    root: null,
    rootMargin: "0px",
  });
  const [ref2, inView2] = useInView({
    threshold: 1,
    root: null,
    rootMargin: "0px",
  });
  const [ref3, inView3] = useInView({
    threshold: 1,
    root: null,
    rootMargin: "0px",
  });
  const [ref4, inView4] = useInView({
    threshold: 1,
    root: null,
    rootMargin: "0px",
  });
  const [ref5, inView5] = useInView({
    threshold: 1,
    root: null,
    rootMargin: "0px",
  });
  const [ref6, inView6] = useInView({
    threshold: 1,
    root: null,
    rootMargin: "0px",
  });
  const [ref7, inView7] = useInView({
    threshold: 1,
    root: null,
    rootMargin: "0px",
  });

  const { setActiveLink } = useContext(NavigationContext);

  useEffect(() => {
    let index: number = inView1
      ? 0
      : inView2
      ? 1
      : inView3
      ? 2
      : inView4
      ? 3
      : inView5
      ? 4
      : inView6
      ? 5
      : inView7
      ? 6
      : 0;
    setActiveLink(index);
  }, [
    inView1,
    inView2,
    inView3,
    inView4,
    inView5,
    inView6,
    inView7,
    setActiveLink,
  ]);

  return (
    <>
      <Box
        component="section"
        id="home"
        ref={ref1}
        sx={{
          paddingTop: "104px",
          paddingBottom: "104px",
          background:
            "linear-gradient(to right, rgba(49, 138, 155, 0.7) ,rgba(14, 103, 154, 0.7) )",
        }}
      >
        <Home />
      </Box>
      <Box
        component="section"
        id="services"
        ref={ref2}
        sx={{
          paddingTop: "104px",
          paddingBottom: "104px",
          backgroundColor: "transparent",
        }}
      >
        <Services />
      </Box>
      <Box
        component="section"
        id="about-us"
        ref={ref3}
        sx={{
          paddingTop: "104px",
          paddingBottom: "104px",
          background:
            "linear-gradient(to right, rgba(49, 138, 155, 0.7) ,rgba(14, 103, 154, 0.7) )",
        }}
      >
        <AboutUs />
      </Box>
      <Box
        component="section"
        id="contact"
        ref={ref4}
        sx={{
          paddingTop: "104px",
          paddingBottom: "104px",
          backgroundColor: "transparent",
        }}
      >
        <Contact />
      </Box>
      <Footer />
    </>
  );
};
