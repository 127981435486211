import { lazy } from "react";
import {
  BrowserRouter,
  // Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Layout from "../components/Layout";
// import DashboardLayout from "../components/Layout";
// import Layout from "../components/Layout";
// import { Loadable } from "../components/Loadable";
// import LoadingScreen from "../screens/Loading";
import { PageNotFound } from "../screens/PageNotFound";
import { Test } from "../screens/Test";
// import Menu from "../screens/Menu";
// const LazyLoadableScreen = (screen: () => any) =>
//   Loadable(lazy(screen), <LoadingScreen />);
// // const BScreen = LazyLoadableScreen(() => import("../screens/B"));
// const TestScreen = LazyLoadableScreen(() => import("../screens/test-screen"));
// const AScreen = LazyLoadableScreen(() => import("../screens/A"));
// const BScreen = LazyLoadableScreen(() => import("../screens/B"));
// const CScreen = LazyLoadableScreen(() => import("../screens/C"));

// const BankCardScreen = lazy(() => import("../screens/BankCardForm"));
// const PageNotFoundScreen = LazyLoadableScreen(
//   () => import("../screens/PageNotFound")
// );

// const PaymentSelectionScreen = lazy(
//   () => import("../screens/PaymentSelection")
// );

// const NewPaymentScreen = lazy(() => import("../screens/NewPayment"));

export const Navigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Test />} />
        </Route>

        {/* <Route index element={<Navigate to="/payment-selection" replace />} /> */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
