import { Box, Container, Stack, IconButton } from "@mui/material";
import { Email, LinkedIn } from "@mui/icons-material";

export const Footer: React.FC = () => {
  return (
    <footer>
      <Box sx={{ padding: "50px 0", backgroundColor: "#272a33" }}>
        <Container
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Stack direction="row" spacing={1}>
            <IconButton aria-label="email" href="mailto:info@codewyrm.com">
              <Email />
            </IconButton>
            <IconButton
              aria-label="LinkedIn"
              href="https://www.linkedin.com/company/codewyrm/"
              target="_blank"
            >
              <LinkedIn />
            </IconButton>
          </Stack>
        </Container>
      </Box>

      <Box sx={{ padding: "15px 0", backgroundColor: "#2e313a" }}>
        <Container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ color: "#95a0ab", marginBottom: "0", lineHeight: "36px" }}>
            <Box component="p">{new Date().getFullYear()} © Codewyrm</Box>
          </Box>
          <div>
            <img src="/logos/logo-white.svg" alt="payment" height="36" />
          </div>
        </Container>
      </Box>
    </footer>
  );
};
