import { Box, darken, styled } from "@mui/system";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
// import { SocketHandler } from "../socket/SocketHandler";
import React from "react";
import { KeyboardArrowUp } from "@mui/icons-material";
import { ScrollDownAnimation } from "../ScrollDownAnimation";
import Sidebar from "./Sidebar";
import Navbar from "./NavBar";
import { menuItems } from "./items";
// import DashboardSidebar from "./DashboardSidebar";
// import { SIDEBAR_WIDTH } from './utils/config';
interface LayoutProps {
  children?: ReactNode;
}

// const DashboardLayoutRoot = styled("div")(() => ({
//   backgroundColor: "orange",
//   // display: "flex",
//   height: "100%",
//   overflowY: "scroll",
//   width: "100%",
// }));

const DashboardLayoutWrapper = styled("div")(({ theme }) => ({
  // position: "relative",
  // display: "flex",
  // flex: "1 1 auto",
  // overflow: "auto",
  // overflow: "hidden",
  // [theme.breakpoints.up('lg')]: {
  //   paddingLeft: `${SIDEBAR_WIDTH}px`,
  // },
}));

const DashboardLayoutContainer = styled("div")({
  // display: "flex",
  // flex: "1 1 auto",
  // overflow: "hidden",
  // paddingTop: "64px",
});

const DashboardLayoutContent = styled("div")({
  // flex: "1 1 auto",
  // height: "100%",
  // overflow: "auto",
  // position: "relative",
  // WebkitOverflowScrolling: "touch",
  // minHeight: "100%",
  // padding: "80px 0 16px",
});

const Layout: FC<LayoutProps> = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] =
    useState<boolean>(false);
  const myRef = useRef(null);
  const { pathname, hash, key } = useLocation();
  const [headerSX, setHeaderSX] = React.useState<{}>({
    backgroundColor: "transparent",
    padding: "20px 0px",
    border: "none",
  });
  const [sidebarSX, setSidebarSX] = React.useState<{}>({
    marginTop: "84px",
  });
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        // const element = document.getElementById(id);
        // if (element) {
        //   element.scrollIntoView();
        // }
        document.location.hash = `#${id}`;
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  useEffect(() => {
    window.addEventListener("scroll", (e) => handleScroll(e));

    return () => {
      // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener("scroll", (e) => handleScroll(e));
    };
  }, []);

  const handleScroll = (e: Event) => {
    if (window.scrollY === 0) {
      setHeaderSX({
        backgroundColor: "transparent",
        padding: "20px 0px",
        border: "none",
      });
      setSidebarSX({
        marginTop: "84px",
      });
    } else {
      setHeaderSX({
        // backgroundColor: "#272a33",
        // backgroundColor: "#556271",
        // backgroundColor: "darken('#013d50',20%)",
        backgroundColor: "#2e313a",
        padding: "10px 0px",
        // margin: "10px 0px",
        border: "none",
      });
      setSidebarSX({
        marginTop: "64px",
      });
    }
  };

  return (
    <>
      <Box
        ref={myRef}
        component="div"
        sx={{
          backgroundColor: "#013d50",
          width: "100%",
        }}
      >
        <Sidebar
          sx={sidebarSX}
          onMobileClose={(): void => setIsSidebarMobileOpen(false)}
          openMobile={isSidebarMobileOpen}
        />
        <Navbar
          sx={headerSX}
          onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
        />
        <Outlet />
      </Box>
      {window.scrollY !== 0 && (
        <KeyboardArrowUp
          fontSize="large"
          onClick={() => {
            // const element = document.getElementById(menuItems[0].anchor);
            // if (element) {
            //   element.scrollIntoView();
            // }
            document.location.hash = `#${menuItems[0].anchor}`;
          }}
          // color="primary"
          sx={{
            zIndex: "1000",
            position: "fixed",
            right: "10px",
            bottom: "10px",
            "&:hover": {
              cursor: "pointer",
            },
            color: "white",
          }}
        />
      )}
    </>
  );
};

export default Layout;
