import { Box, Container, Grid, Typography } from "@mui/material";

export const Home: React.FC = () => {
  return (
    <Container>
      {/* <Typography variant="h2" sx={{ mb: "40px" }}>
        HOME
      </Typography> */}
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          sx={(theme) => ({
            width: "50%",
            [theme.breakpoints.down("lg")]: {
              display: "flex",
              justifyContent: "space-around",
            },
            [theme.breakpoints.up("lg")]: {},
          })}
        >
          <Box
            component="img"
            src="logos/logo.svg"
            sx={(theme) => ({
              width: "50%",

              [theme.breakpoints.down("md")]: {
                width: "80%",
              },
            })}
            // sx={{
            //   backgroundImage: `url(logos/logo-white.svg)`,
            //   backgroundRepeat: "no-repeat",
            //   position: "absolute",
            //   backgroundSize: "30vh",
            //   height: "100%",
            //   width: "100%",
            //   zIndex: 2,
            // }}
          />
        </Grid>
        <Grid item md={12} lg={6}>
          <Typography
            variant="h2"
            sx={(theme) => ({
              textAlign: "justify",
            })}
          >
            Codewyrm is where we take your brilliant ideas and fuse them with
            our innovative technology to create some awesome software!
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};
