import { Box, Container, Typography } from "@mui/material";
// import OnVisible, { setDefaultProps } from "react-on-visible";

export const AboutUs: React.FC = () => {
  return (
    <Container>
      <Typography variant="h2" sx={{ mb: "40px" }}>
        About Us
      </Typography>
      <Box component="div">
        <Box component="p">
          In late 2020 an idea emerged to bring a group of talented individuals
          together to create bespoke and innovative software. We strive to
          provide our clients with the best technology suited for their
          requirements by taking the time to get to know their needs and
          understand what they are looking to achieve.
        </Box>
        <Box component="p">
          With over 15 years of experience within Tech our team has brought
          experience from several industries such as Gaming, Finance &#38;
          Banking, Telecommunications, Healthcare, Hospitality , Anti-Fraud and
          Compliance.
        </Box>
        <Box component="p">
          We pride ourselves in our innovavative IT solutions and nourish our
          skills with the adoption of emerging technologies, digital
          transformation and self-exploration by handling all kinds of
          operations across the globe.
        </Box>
        The solutions we provide cover top to bottom technical needs including
        Consulting, System Integration Services, Software Development, Mobile
        Solutions, Web Development and much much more!
        <Box component="p">
          Our adaptability and flexibility allow us to drop into any problem and
          figure out the best solution for you!
        </Box>
        <Box component="p">
          Here at Codewyrm we believe in quality above all else and strive to
          use cutting edge technology to meet your requirements. Intrested? Hit
          us up to find out more!
        </Box>
      </Box>
    </Container>
  );
};
