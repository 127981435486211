import { Box, Button, ListItem, ListItemProps } from "@mui/material";
import { FC, ReactNode, useContext } from "react";
import { NavigationContext } from "../../../contexts/navigation-context";
import { useLocation } from "react-router-dom";

interface SidebarItemProps extends ListItemProps {
  info?: ReactNode;
  anchor?: string;
  title: string;
  index: number;
  onMobileClose: () => void;
}

const SidebarItem: FC<SidebarItemProps> = (props) => {
  const { info, anchor, title, index, onMobileClose, ...other } = props;
  const { pathname, hash, key } = useLocation();
  const { active } = useContext(NavigationContext);

  let paddingLeft = 16;

  const goToAnchor = (anchor: string) => {
    // const element = document.getElementById(anchor);
    // if (element) {
    setTimeout(function () {
      // element.scrollIntoView();
      document.location.hash = `#${anchor}`;
    }, 500);
    // }
    onMobileClose();
  };

  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        py: 0,
      }}
    >
      <Button
        sx={{
          fontWeight: "fontWeightMedium",
          justifyContent: "flex-start",
          textAlign: "left",
          pl: `${paddingLeft}px`,
          pr: "8px",
          py: "12px",
          textTransform: "none",
          width: "100%",
        }}
        onClick={() => goToAnchor(anchor as string)}
      >
        <Box
          sx={{
            flexGrow: 1,
            color:
              hash === `#${anchor}` || (hash.length === 0 && anchor == "home")
                ? "white"
                : "rgba(255, 255, 255, 0.6)",
            fontWeight: active === index ? "fontWeightBold" : "600",
          }}
        >
          {title}
        </Box>
        {info}
      </Button>
    </ListItem>
  );
};

export default SidebarItem;
