import React from "react";
import "./App.css";
import { CacheProvider } from "@emotion/react";
import { createEmotionCache } from "./utils/create-emotion-cache";
// import { WithMaterialUI } from './form';
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
// import theme from './theme/theme';
import {
  SettingsConsumer,
  SettingsProvider,
} from "./contexts/settings-context";
import { RTL } from "./components/system/rtl";
import { createTheme } from "./services/theme";
import { Navigation } from "./navigation";
import { NavigationContextProvider } from "./contexts/navigation-context";
function App() {
  const emotionCache = createEmotionCache();

  const defaultColor = "#1976d2";
  const [primaryColor, setPrimaryColor] = React.useState(defaultColor);
  const overrides = React.useMemo(() => {
    return {
      palette: {
        primary: {
          // main: primaryColor,
        },
      },
    };
  }, [primaryColor]);

  return (
    <CacheProvider value={emotionCache}>
      <SettingsProvider>
        <SettingsConsumer>
          {({ settings }) => (
            <ThemeProvider
              theme={{
                ...createTheme({
                  direction: settings.direction,
                  responsiveFontSizes: settings.responsiveFontSizes,
                  mode: settings.theme,
                  overrides: overrides,
                }),
              }}
            >
              <RTL direction={settings.direction}>
                <CssBaseline />
                <NavigationContextProvider>
                  <Navigation />
                </NavigationContextProvider>
              </RTL>
            </ThemeProvider>
          )}
        </SettingsConsumer>
      </SettingsProvider>
    </CacheProvider>
  );
}

export default App;
