import { Menu } from "@mui/icons-material";
import {
  AppBar,
  AppBarProps,
  Box,
  Container,
  IconButton,
  Link,
  Toolbar,
} from "@mui/material";
import { styled } from "@mui/system";
import PropTypes from "prop-types";
import { FC, useContext } from "react";
import { NavigationContext } from "../../../contexts/navigation-context";
import { Logo } from "../../Logo";
import { menuItems } from "../items";

interface NavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
  sx: {};
}

const NavbarRoot = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: "#ffffff",
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  position: "absolute",
  left: 0,
  right: 0,
  width: "auto",
  borderBottom: "1px solid #E4E7EB",
}));

const Navbar: FC<NavbarProps> = (props) => {
  const { onSidebarMobileOpen, sx, ...other } = props;

  const { active } = useContext(NavigationContext);

  return (
    <AppBar
      id="navBar"
      sx={[
        (theme) => ({
          ...(theme.palette.mode === "light" && {
            backgroundColor: "#ffffff",
            boxShadow: "none",
            color: theme.palette.primary.contrastText,
          }),
          ...(theme.palette.mode === "dark" && {
            backgroundColor: theme.palette.background.paper,
            boxShadow: "none",
          }),
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          width: "auto",
          borderBottom: "1px solid #E4E7EB",
          ...sx,
          transition: "all 0.5s ease-in-out",
        }),
      ]}
      {...other}
    >
      <Toolbar
        sx={{
          minHeight: 64,
          maxHeight: 64,
        }}
      >
        <Container
          sx={(theme) => ({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          })}
        >
          <Link href="#home">
            <Logo />
          </Link>

          <Box
            sx={(theme) => ({
              [theme.breakpoints.down("md")]: {
                display: "none",
              },
            })}
          >
            {menuItems.map((item, index) => (
              <Link
                key={item.anchor}
                sx={{
                  textDecoration: "none",
                  lineHeight: "26px",
                  color:
                    active === index ? "white" : "rgba(255, 255, 255, 0.6)",
                  fontSize: "15px",
                  textTransform: "capitalize",
                  transition: "all 0.5s",
                  backgroundColor: "transparent !important",
                  padding: "15px 0",
                  margin: "0 10px",
                  fontWeight: "bold",
                }}
                href={`#${item.anchor}`}
              >
                {item.title}
              </Link>
            ))}
          </Box>
        </Container>
        <IconButton
          onClick={onSidebarMobileOpen}
          sx={(theme) => ({
            [theme.breakpoints.up("md")]: {
              display: "none",
            },
          })}
        >
          <Menu
            sx={(theme) => ({
              color: "white",
            })}
            fontSize="large"
          />
        </IconButton>
        {/* <LanguagePopover /> */}
        {/* <Box sx={{ ml: 1 }}>
          <ContentSearch />
        </Box> */}
        {/* <Box sx={{ ml: 1 }}>
          <ContactsPopover />
        </Box> */}
        {/* <Box sx={{ ml: 1 }}>
          <NotificationsPopover />
        </Box> */}
        {/* <Box sx={{ ml: 2 }}>
          <NotificationsPopover />
        </Box> */}
        {/* <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box> */}
      </Toolbar>
    </AppBar>
  );
};

Navbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default Navbar;
